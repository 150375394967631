import { Button, Checkbox, FormGroup } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import * as colors from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import * as commonFnc from '../common/commonFnc';
import * as BL_requestKintai from '../BL/BL_requestKintai_tmp';
import * as BL_kintai from '../BL/BL_kintai';
import * as BL_NFC from '../BL/BL_NFC';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {GlobalContext} from '../globalState'
import { useHistory } from 'react-router-dom';
import useSound from 'use-sound';
import SoundOk from '../audio/touch_ok2.mp3';
import SoundNg from '../audio/touch_ng1.mp3';




const theme = createTheme({
  typography: {
    fontFamily: [
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "ヒラギノ角ゴ ProN",
      "Hiragino Kaku Gothic ProN",
      "メイリオ",
      "Meiryo",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    // primary: { main: colors.blue[800] }, // テーマの色
    primary: { main: colors.lightGreen[400] }, // テーマの色
  },
});

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    toolbar: {
      paddingRight: 24,
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    title: {
      flexGrow: 1,
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    link: {
      textDecoration: "none",
      color: theme.palette.text.secondary,
    },
  })
);                                          



const ClockinFormNfc = (props) =>{
    const classes = useStyles();
    const {Clockin_info, setClockin_info, Users, Shift_msts, Department_msts, Login_user_info} = props;

    const history = useHistory();
    const Env_msts = useContext(GlobalContext).Env_msts;
    const UNIT_WORK_TIME_TYPE = Env_msts.find(p => p.env_key === "UNIT_WORK_TIME_TYPE")?.env_value;
    const shift_group_cd_hash = useContext(GlobalContext).shift_group_cd;

    const [approval_status_cd, setApproval_status_cd] = useState("10");

    const [Succsess, setSuccess] = useState(false);
    const [switchA, setSwitchA] = useState(false);
  
    const [idm, setIdm] = useState("");
    const [idmMsg, setIdmMsg] = useState("接続開始");
    const [isDisable_nfc_button, setIsDisable_nfc_button] = useState(false);
    const [playOk, { stopOk, pauseOk }] = useSound(SoundOk);
    const [playNg, { stopNg, pauseNg }] = useSound(SoundNg);


    const [count1State, setCount1State] = useState({});
    const [count2State, setCount2State] = useState({});
    const [count3State, setCount3State] = useState({});
    const [count4State, setCount4State] = useState({});
    const [count5State, setCount5State] = useState({});
    const tmp_count1 = Env_msts.find(p => p.env_key === "COUNT1");
    const tmp_count2 = Env_msts.find(p => p.env_key === "COUNT2");
    const tmp_count3 = Env_msts.find(p => p.env_key === "COUNT3");
    const tmp_count4 = Env_msts.find(p => p.env_key === "COUNT4");
    const tmp_count5 = Env_msts.find(p => p.env_key === "COUNT5");
    useEffect(()=>{
      setCount1State({
          name:tmp_count1?.env_value,
          isHidden:tmp_count1?.log_del_flg,
          isChecked:false,
      });
      setCount2State({ 
          name:tmp_count2?.env_value,
          isHidden:tmp_count2?.log_del_flg,
          isChecked:false,
      });
      setCount3State({
          name:tmp_count3?.env_value,
          isHidden:tmp_count3?.log_del_flg,
          isChecked:false,
      });
      setCount4State({
          name:tmp_count4?.env_value,
          isHidden:tmp_count4?.log_del_flg,
          isChecked:false,
      });
      setCount5State({
          name:tmp_count5?.env_value,
          isHidden:tmp_count5?.log_del_flg,
          isChecked:false,
      });
    },[Env_msts]);

    const handleCheck = (e, Obj,setObj) =>{
      const target = e.target;
      const checked = target.checked;
      const name = target.name;
      setObj({ ...Obj, [name]: checked });
    }
    
    /**
     * 社内からのアクセスか判定する。
     */
    const checkInternalAccess = async () =>{
      
      const internalAccess = await commonFnc.isInternalAccess();
      if (!internalAccess) {
        alert("社外からのアクセスは不正です。");
        history.push('/');
      }
    }
    
    
    var intervalId = null;
    useEffect(()=>{
      checkInternalAccess();
    },[])


    const handleInputChage = (e) =>{
        const target = e.target;
        const value = target.value;
        const name = target.name;
        setClockin_info({ ...Clockin_info, [name]: value });
    }


    /**
     * 1秒ごとに画面を更新する。
     */
    const countup = () => {
        var now_date = new Intl.DateTimeFormat("ja-jp", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date());
          
        var now_time = new Intl.DateTimeFormat("ja-jp", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }).format(new Date());
        
          document.getElementById("localClock_date") ? document.getElementById("localClock_date").innerHTML = now_date :clearInterval(intervalId);;
          document.getElementById("localClock_time") ? document.getElementById("localClock_time").innerHTML = now_time :clearInterval(intervalId);;
    };

    intervalId = setInterval(countup, 1000);


    /**
     * submit処理(バリデーション込み)
     * @param {*} clockin_type_cd 
     * @param {*} Clockin_info 
     * @returns 
     */
    const varidateSubmit = async (clockin_type_cd, Clockin_info, tmp_approval_status_cd, tmp_switchA) =>{

      var msg = ""
      if(!clockin_type_cd){
          msg += "出退勤種別を選択してください。"
      }
      if(!Clockin_info.user_id){
          msg += "職員を選択してください。"
      }
      // if(!Clockin_info.shift_type_cd){
      //     msg += "シフトを選択してください。"
      // }
      if(msg.length>0){
          setIdmMsg(msg);
          return false;
      }

      // if(clockin_type_cd === "90" && Boolean(Clockin_info.shift_type_cd)){
      //   var result = window.confirm('出勤時に選択したシフトが優先されます。よろしいですか。');
      //   if( !result ) {
      //     return false;
      //   }
      // }
      
      const now = new Date();
      setClockin_info({
          ...Clockin_info,
          date : commonFnc.formatDate(now),
          time : commonFnc.ConvertJSTHHMM(now),
          clockin_type_cd : clockin_type_cd
      })

      const tmp_user_id = Clockin_info.user_id;
      const tmp_date = commonFnc.formatDate(now);
      const tmp_time = commonFnc.ConvertJSTHHMM(now);
      const tmp_clockin_type_cd = clockin_type_cd;
      const tmp_shift_type_cd = Clockin_info.shift_type_cd;
      const tmp_department_cd = Clockin_info.department_cd;
      const tmp_first_approval_userid = Clockin_info.first_approval_userid;
      const tmp_second_approval_userid = Clockin_info.second_approval_userid;

      // ※シフト取り込みを作成したことで打刻時にシフトを選択しなくなったため自動で日付切り替えは削除

      // 打刻時に選択したシフトコードからシフト情報を取得
      // const shift_mst = Shift_msts.find((p)=>p.shift_type_cd === tmp_shift_type_cd);
      // const baseTimeFrom = new Date(tmp_date + " " + "00:00:00");
      // const baseTimeTo = new Date(tmp_date + " " + (shift_mst?.shift_switching_time?shift_mst?.shift_switching_time:"00:00") + ":00"); 

      // 日付切り替え時刻を超えていないなら前日のシフトに登録
      // var shiftDate = now;
      // if(now >= baseTimeFrom && now < baseTimeTo){
      //     shiftDate.setDate(shiftDate.getDate() - 1)
      // }

      // 日付切り替えスイッチの値に依って取得する日付を切り替える。
      var shiftDate = now;
      
      if(tmp_switchA.toLowerCase() === "true"){
        shiftDate.setDate(shiftDate.getDate() - 1)
      }
      const fmt_shiftDate = commonFnc.formatDate(shiftDate);

      // 登録済みの勤怠情報を取得
      const query = new URLSearchParams({ date: fmt_shiftDate, user_id: tmp_user_id });
      const res = await fetch(`/api/select/one_kintai_bsc?${query}`);
      const kintaiData = await res.json();

      // 打刻情報登録 
      var url = "/api/update/clockin_jarnal"
      var bodyData = {
          shift_date : fmt_shiftDate,
          date: tmp_date,
          user_id:tmp_user_id,
          department_cd:tmp_department_cd,
          time:tmp_time,
          clockin_type_cd:tmp_clockin_type_cd,
          shift_type_cd:tmp_shift_type_cd
      }

      try {
        const requestOptions = {
          method: 'POST',
          headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
          body: JSON.stringify(bodyData)
        };
    
    
        commonFnc.insertUserLog("TRACE", tmp_user_id, __filename, "打刻ページ", "打刻処理", "追加処理", "開始", null, bodyData);
        const response = await fetch(url, requestOptions);
        const data = await response.json();
        // setInsertInfo(data);
        setSuccess(true);
        setTimeout(function () {
          setSuccess(false)
        }.bind(this), 5000);
    
        commonFnc.insertUserLog("DEBUG", tmp_user_id, __filename, "打刻ページ", "打刻処理", "追加処理", "完了", null, bodyData);

        // //////////////// 勤怠情報更新処理 start ////////////////////////////
        const shift = Shift_msts.find(p=>p.shift_type_cd === tmp_shift_type_cd);

        const jstDateTime = new Date(data.date + " " + data.time + ":00Z");
        const utcDateTime = new Date(jstDateTime.getTime() - 9 * 60 * 60 * 1000);
    
        const name = clockin_type_cd === "10" ? "work_time_from" : "work_time_to"; // 打刻区分に依って出勤、退勤を判定

        // 打刻時にはpaid_holiday_timeに0を設定
        var bodyData_kintai = BL_requestKintai.changeWorkTime(name, utcDateTime, kintaiData, Shift_msts, shift_group_cd_hash, 0, UNIT_WORK_TIME_TYPE);
    
        bodyData_kintai = {
          ...bodyData_kintai,
          date: fmt_shiftDate,
          user_id: tmp_user_id,
          shift_type_cd: tmp_shift_type_cd ? tmp_shift_type_cd : kintaiData?.shift_type_cd,
          harf_holiday_shift_type_cd: tmp_shift_type_cd ? null : kintaiData?.harf_holiday_shift_type_cd,
          first_approval_userid: tmp_first_approval_userid ? tmp_first_approval_userid : "",
          second_approval_userid: tmp_second_approval_userid ? tmp_second_approval_userid : "",
          approval_status_cd:tmp_approval_status_cd,
          count1value: count1State.isChecked ? count1State.isChecked : kintaiData?.count1,
          count2value: count2State.isChecked ? count2State.isChecked : kintaiData?.count2,
          count3value: count3State.isChecked ? count3State.isChecked : kintaiData?.count3,
          count4value: count4State.isChecked ? count4State.isChecked : kintaiData?.count4,
          count5value: count5State.isChecked ? count5State.isChecked : kintaiData?.count5,
          public_holiday_time: shift?.public_holiday_time,
          paid_holiday_time: shift?.paid_holiday_time,
          log_del_flg: false
        };

        // 打刻者の情報を取得
        const queryClockinUser = new URLSearchParams({ user_id: tmp_user_id });
        const res = await fetch(`/api/select/user?${queryClockinUser}`);
        const clockinUserInfo = await res.json();
        
        // 承認CDが20なら
        if(tmp_approval_status_cd==="20"){
          if(!bodyData_kintai.shift_type_cd){
            // エラー：シフトCdが登録されていないので申請退勤は出来ません。
            setIdmMsg("シフトCdが登録されていないため申請退勤はできません。");
            
            return false;
          }
          
          // 次回承認者を取得してbodyに格納
          const next_approval_user_id = BL_kintai.getNextApprovalUser(clockinUserInfo, Department_msts, "10");
          bodyData_kintai = {
            ...bodyData_kintai,
            next_approval_userid:next_approval_user_id,
            last_approval_userid:tmp_user_id,
          }
          
        }
    
        commonFnc.insertUserLog("TRACE", tmp_user_id, __filename, "打刻ページ", "勤怠打刻情報登録", "追加処理", "開始", null, bodyData_kintai);
        const url_kintai = "/api/update/kintai_bsc_clockin";
        const requestOptions_kintai = {
          method: 'POST',
          headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
          body: JSON.stringify(bodyData_kintai)
        };
    
        await fetch(url_kintai, requestOptions_kintai);
        commonFnc.insertUserLog("DEBUG", tmp_user_id, __filename, "打刻ページ", "勤怠打刻情報登録", "追加処理", "完了", null, bodyData_kintai);
    
        // //////////////// 勤怠情報更新処理 end //////////////////////////////
        // //////////////// 公休有給更新処理 start //////////////////////////////

        // 未選択は何もしない
        if(tmp_shift_type_cd){
          const beforeShiftTypeCd = kintaiData?.shift_type_cd;
          const afterShiftTypeCd = tmp_shift_type_cd;
          
          // 有給公休　加減算
          const reducePublicHolidayTime = BL_requestKintai.getReduceHolidayTime(
                                                      Shift_msts, 
                                                      beforeShiftTypeCd, 
                                                      afterShiftTypeCd, 
                                                      "public_holiday_time",
                                                  );
          const reducePaidHolidayTime = BL_requestKintai.getReduceHolidayTime(
                                                      Shift_msts, 
                                                      beforeShiftTypeCd, 
                                                      afterShiftTypeCd, 
                                                      "paid_holiday_time",
                                                  );
  
          // 半日シフトを考慮　半日シフトは削除するためafterをnullで渡す。
          const beforeHarfHolidayShiftTypeCd = kintaiData?.harf_holiday_shift_type_cd;
          const reduceHarfHolidayShiftPublicHolidayTime = BL_requestKintai.getReduceHolidayTime(
                                                                    Shift_msts, 
                                                                    beforeHarfHolidayShiftTypeCd, 
                                                                    null, 
                                                                    "public_holiday_time",
                                                                );
                                                                  
          const reduceHarfHolidayShiftPaidHolidayTime = BL_requestKintai.getReduceHolidayTime(
                                                                    Shift_msts, 
                                                                    beforeHarfHolidayShiftTypeCd, 
                                                                    null, 
                                                                    "paid_holiday_time",
                                                                );
  
          const reducePublicHolidayDays = (reducePublicHolidayTime+reduceHarfHolidayShiftPublicHolidayTime)/8;
          const reducePaidHolidayDays = (reducePaidHolidayTime+reduceHarfHolidayShiftPaidHolidayTime)/8;
  
          var bodyData_userInfo = {
            user_id: tmp_user_id,
            enable_public_holiday_days: clockinUserInfo.enable_public_holiday_days - reducePublicHolidayDays,
            enable_paid_holiday_days: clockinUserInfo.enable_paid_holiday_days - reducePaidHolidayDays,
          };
          const url_userInfo = "/api/update/user_holiday_info";
          const requestOptions_userInfo = {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(bodyData_userInfo)
          };
      
          await fetch(url_userInfo, requestOptions_userInfo);

        }


        // //////////////// 公休有給更新処理 end //////////////////////////////

        // 打刻画面の初期化処理
        setClockin_info({
          date: "",
          department_cd: "",
          user_id: "",
          time: "",
          shift_type_cd: "",
          clockin_type_cd: ""
        });
      } catch (error) {
        commonFnc.insertUserLog("ERROR", tmp_user_id, __filename, "打刻ページ", "打刻処理", "追加処理", error.name + " : " + error.message, null, null);
        return false;
      }
      return true;

    }


    /**
     * Felicaの読み込みを待機する。
     */
    const waitNfc = async ()=>{
        let device;

        // 前処理(接続)
        try {
          
          const ud = await navigator.usb.getDevices() ;	// ペアリング設定済みデバイスのUSBDeviceインスタンス取得
          let pearedDevices = null ;
          if(ud){
            pearedDevices = ud.filter((p)=>p.vendorId === 0x054c);
          }

          // ペアリング済みのデバイスが1台だけならそのデバイスと接続。
          if(pearedDevices.length === 1){
            device = pearedDevices[0];
          }else{
            device = await navigator.usb.requestDevice({ filters: [{ vendorId: 0x054c }] })
          }
          console.log("open");
          await device.open();
        } catch (e) {
          // 接続開始前に発生したエラー
          // EX)　USBが刺さっていない、ドライバ未インストールで認識しない等
          handleErrOccurred("タッチデバイスと接続できません。");
          return;
        }


        // 本処理(カード読み取り)
        try {
          console.log("selectConfiguration");
          await device.selectConfiguration(1);
          console.log("claimInterface");
          await device.claimInterface(0);
          do {
            const idm = await BL_NFC.session(device);
            if(idm?.length > 0){
              
              const query = new URLSearchParams({ idm: idm });
              const res = await fetch(`/api/select/user_from_idm?${query}`);
              const tmp_user = await res.json();
              if(tmp_user){
                
                const queryDept = new URLSearchParams({ department_cd: tmp_user.department_cd });
                const resDept = await fetch(`/api/select/department_mst?${queryDept}`);
                const tmp_dept = await resDept.json();

                setIdmMsg('Touch！\n' + tmp_user.first_name + " " + tmp_user.second_name);
                let tmp_clockin_info = getClockinInfo();
                tmp_clockin_info = {
                  ...tmp_clockin_info,
                  user_id:tmp_user.user_id,
                  department_cd:tmp_user.department_cd,
                  first_approval_userid:tmp_user.first_approval_userid ? tmp_user.first_approval_userid : tmp_dept.first_approval_userid,
                  second_approval_userid:tmp_user.second_approval_userid ? tmp_user.second_approval_userid : tmp_dept.second_approval_userid
                }
                setClockin_info(tmp_clockin_info);
                
                const result = await varidateSubmit(tmp_clockin_info.clockin_type_cd, tmp_clockin_info, getApprovalStatusCd(), getSwitchA());
                result?playOk():playNg();

                // ボタンクリア
                setSwitchA(false);
                setCount1State({
                  ...count1State,
                  isChecked:false,
                });
                setCount2State({
                  ...count2State,
                  isChecked:false,
                });
                setCount3State({
                  ...count3State,
                  isChecked:false,
                });
                setCount4State({
                  ...count4State,
                  isChecked:false,
                });
                setCount5State({
                  ...count5State,
                  isChecked:false,
                });
                
              }else{
                playNg();
                setIdmMsg("登録されていないカードです。");
              }
              setIdm(idm)
              await BL_NFC.sleep(2000);
            }else{
              setIdmMsg("カードをかざしてください。");
              setIdm(idm)
            }
            setIsDisable_nfc_button(true);
            await BL_NFC.sleep(500);
          } while (true);
        } catch (e) {
          // 接続開始後に発生したエラー
          // EX)　USBが抜けた等
          playNg();
          handleErrOccurred("タッチデバイスとの接続が解除されました。");
          return;
        }
    }

    /** 例外発生時の目セージ表示処理 */
    const handleErrOccurred = async (msg)=>{
      setIsDisable_nfc_button(false);
      setIdmMsg(msg);
      await BL_NFC.sleep(2000);
      setIdmMsg("接続開始");
    }

    /**
     * DOMから打刻情報を取得
     * @returns 
     */
    const getClockinInfo = () =>{

      const department_cd = document.getElementById("department_cd_value").value;
      const user_id = document.getElementById("user_id_value").value;
      const clockin_type_cd = document.getElementById("clockin_type_cd_value").value;
      const shift_type_cd = document.getElementById("shift_type_cd_value").value;

      const tmp_clockin_info = {
        date : commonFnc.formatDate(new Date()),
        time : commonFnc.ConvertJSTHHMM(new Date()),
        user_id:user_id,
        department_cd:department_cd,
        clockin_type_cd:clockin_type_cd,
        shift_type_cd: shift_type_cd
      }

      return tmp_clockin_info;

    }

    const getSwitchA = () =>{
      return document.getElementById("switchA_value").value;
    }

    const getApprovalStatusCd = () =>{
      return document.getElementById("approval_status_cd_value").value;
    }


    // Switchのスタイルをカスタマイズ
    const LargeSwitch = withStyles({
      root: {
        width: 120, // スイッチ全体の幅
        height: 48, // スイッチ全体の高さ
        // paddingRight:20,
      },
      switchBase: {
        // width: 50, // つまみの幅
        padding: 5, // つまみ周りの余白
        '&$checked': {
          transform: 'translateX(75px)', // チェック時の移動距離
          // color: '#fff',
        },
      },
      thumb: {
        margin: 5, // つまみ周りの余白
        width: 28, // つまみの幅
        height: 28, // つまみの高さ
      },
      track: {
        height: 24, // トラック部分の高さ
        minWidth: 100, // トラック部分の幅
        borderRadius: 12, // トラック部分の角丸
        backgroundColor: '#bdbdbd', // トラックのデフォルト色
        opacity: 1,
        '$checked + &': {
          backgroundColor: '#1976d2', // チェック時のトラック色
        },
      },
      checked: {}, // チェック状態のスタイル用
    })(Switch);

    
    return (
            
        <ThemeProvider theme={theme}>
          <div className={classes.root}>
            <CssBaseline />
            <AppBar
              position="absolute"
            >
        <Toolbar className={classes.toolbar}>
            <div class="clockin_toolbar">
            <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
            >
            </Typography>
            </div>
        </Toolbar>
        </AppBar>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="xl" className={classes.container}>
            <form action="/api/update/clockin_jarnal" method="post" name="clockinForm" id="clockinForm">
                <div class="clockin_main_container">
                    <div class="clockin_container">
                        <div class="clockin_contents">
                          <div class="  ">
                            <div id="localClock_date" class="clockin_time"></div>
                            <div id="localClock_time" class="clockin_time"></div>
                            <div class="result_message">
                              { !Succsess ? "" :
                                      "打刻しました。"
                              }
                            </div>
                          </div>
                        </div>
                        <div class="clockin_contents">
                          <Button 
                            className={Clockin_info.clockin_type_cd==="10"?"clockin_type_button enter_botton":"clockin_type_button enter_botton not_selected"} 
                            variant="outlined" 
                            onClick={()=>{setApproval_status_cd("10");setClockin_info({...Clockin_info, clockin_type_cd:"10"})}}
                          >
                              出勤
                          </Button>
                          <Button  
                            className={(Clockin_info.clockin_type_cd==="90"&&approval_status_cd==="10")?"clockin_type_button outer_button":"clockin_type_button outer_button not_selected"} 
                            variant="outlined" 
                            onClick={()=>{setApproval_status_cd("10");setClockin_info({...Clockin_info, clockin_type_cd:"90", shift_type_cd:""})}} 
                          >
                              退勤
                          </Button>
                          <Button  
                            className={(Clockin_info.clockin_type_cd==="90"&&approval_status_cd==="20")?"clockin_type_button outer_button":"clockin_type_button outer_button not_selected"} 
                            variant="outlined" 
                            onClick={()=>{setApproval_status_cd("20");setClockin_info({...Clockin_info, clockin_type_cd:"90", shift_type_cd:""})}} 
                          >
                              申請<br/>退勤
                          </Button>
                        </div>
                        <div class="clockin_contents">
                          <div>
                            <TextField
                                select
                                label="シフト"
                                variant="outlined"
                                name="shift_type_cd" 
                                value={Clockin_info.shift_type_cd}
                                onChange={(e)=>handleInputChage(e)}
                                className="clockin_select select_tworow"
                            >
                                {Shift_msts.sort((a, b) => a.seq < b.seq ? -1 : 1).filter((p)=>(!(p.shift_group_cd===shift_group_cd_hash["HOLIDAY_SHIFT_GROUP_CD"] 
                                                                                                    || p.shift_group_cd===shift_group_cd_hash["HARF_HOLIDAY_SHIFT_GROUP_CD"] 
                                                                                                    || p.shift_group_cd===shift_group_cd_hash["TIME_PAID_HOLIDAY_SHIFT_GROUP_CD"] 
                                                                                                    || p.shift_group_cd===shift_group_cd_hash["SICK_LEAVE_GROUP_CD"] 
                                                                                                    || p.shift_group_cd===shift_group_cd_hash["HARF_SICK_LEAVE_GROUP_CD"] )) && p.log_del_flg===false).map((shist_mst, index)=>(
                                    <MenuItem
                                      key={index}
                                      value={shist_mst.shift_type_cd}
                                      className="clockin_menuitem">
                                        {shist_mst.shift_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                          </div>
                        {/* </div>
                        <div class="clockin_contents"> */}
                          <div>
                              <FormGroup row className="counterContent">
                                  {count1State.isHidden === undefined || count1State.isHidden?"":
                                      <FormControlLabel
                                          control={<Checkbox checked={count1State.isChecked} onChange={e => handleCheck(e, count1State, setCount1State)} name="isChecked"/>}
                                          label={count1State.name}
                                      />
                                  }
                                  {count2State.isHidden === undefined || count2State.isHidden?"":
                                      <FormControlLabel
                                          control={<Checkbox checked={count2State.isChecked} onChange={e => handleCheck(e, count2State, setCount2State)} name="isChecked" />}
                                          label={count2State.name}
                                      />
                                  }
                                  {count3State.isHidden === undefined || count3State.isHidden?"":
                                      <FormControlLabel
                                          control={<Checkbox checked={count3State.isChecked} onChange={e => handleCheck(e, count3State, setCount3State)} name="isChecked" />}
                                          label={count3State.name}
                                      />
                                  }
                                  {count4State.isHidden === undefined || count4State.isHidden?"":
                                      <FormControlLabel
                                          control={<Checkbox checked={count4State.isChecked} onChange={e => handleCheck(e, count4State, setCount4State)} name="isChecked" />}
                                          label={count4State.name}
                                      />
                                  }
                                  {count5State.isHidden === undefined || count5State.isHidden?"":
                                      <FormControlLabel
                                          control={<Checkbox checked={count5State.isChecked} onChange={e => handleCheck(e, count5State, setCount5State)} name="isChecked" />}
                                          label={count5State.name}
                                      />
                                  }
                              </FormGroup>
                          </div>
                        </div>
                        <div class="clockin_contents switch_contents">
                            <div class="switch">
                                <FormControlLabel
                                    control={
                                      <LargeSwitch
                                        checked={switchA}
                                        onChange={() => setSwitchA(!switchA)}
                                        name="date"
                                      />
                                    }
                                    labelPlacement="start"
                                    label="夜勤終わり(前日のシフトに登録)"
                                  />
                            </div>
                        </div>
                        <div class="clockin_contents">
                          <Button className="nfc_connect_button" variant="outlined" onClick={()=>waitNfc()} disabled={isDisable_nfc_button}>
                            <div class="nfc_loader" hidden={!isDisable_nfc_button || idm}></div>
                            <div>{idmMsg}</div>
                          </Button>
                        </div>
                    </div>
                </div>
                <input hidden readOnly id="department_cd_value" value={Clockin_info.department_cd}></input>
                <input hidden readOnly id="user_id_value" value={Clockin_info.user_id}></input>
                <input hidden readOnly id="clockin_type_cd_value" value={Clockin_info.clockin_type_cd}></input>
                <input hidden readOnly id="shift_type_cd_value" value={Clockin_info.shift_type_cd}></input>
                <input hidden readOnly id="approval_status_cd_value" value={approval_status_cd}></input>
                <input hidden readOnly id="switchA_value" value={switchA}></input>
                
                
            </form>
        </Container>
        </main>
      </div>
    </ThemeProvider>
    );
}

export default ClockinFormNfc;