import KintaiHeader from "../conponents/KintaiHeader";
import KintaiTable from "../conponents/KintaiTable";
import GenericTemplate from "../templates/GenericTemplate";
import '../css/Loading.css';
import { useEffect } from "react";

const Kintai = (props) =>{
    const {
        login_user_id, 
        setLogin_user_id, 
        Kintai_bscs, 
        setKintai_bscs, 
        Shift_msts, 
        Kintai_bsc, 
        setKintai_bsc, 
        Department_msts,
        setRequest_overtimes,
        Request_paid_holiday, 
        setRequest_paid_holiday, 
        Login_user_info, 
        setLogin_user_info, 
        Users, 
        setUsers,
        shiftStartDay, 
        setShiftStartDay, 
        display_user_info, 
        setDisplay_user_info
    } = props;

    useEffect(()=>{
        // ユーザマスタ更新時に公休数が再取得されない問題に対応
        // ユーザマスタとLoginuserinfoを更新

        fetch("api/select/users")
        .then((res)=> res.json())
        .then((data)=>{setUsers(data)});

        if(Login_user_info.user_id === display_user_info.user_id){
            const queryGetLoginUser = new URLSearchParams({user_id: login_user_id});
            fetch(`/api/select/login_user?${queryGetLoginUser}`)
            .then((res)=> res.json())
            .then((data)=>{setLogin_user_info(data)})
        }

    },[]);


    return (
        
        
        <GenericTemplate title="勤務カレンダー"
            setLogin_user_id={setLogin_user_id}
            setKintai_bscs={setKintai_bscs}
            Login_user_info={Login_user_info}
            setDisplay_user_info={setDisplay_user_info}
        >
            {!Login_user_info?<div class="loader">Loading...</div>:
            <div>
                <KintaiHeader
                    shiftStartDay={shiftStartDay}
                    setShiftStartDay={setShiftStartDay}
                    Kintai_bscs={Kintai_bscs} 
                    Login_user_info={Login_user_info}
                    display_user_info={display_user_info}
                    Users={Users}
                />
                <KintaiTable 
                    setLogin_user_info={setLogin_user_info}
                    login_user_id={login_user_id}
                    Kintai_bscs={Kintai_bscs} 
                    setKintai_bscs={setKintai_bscs} 
                    Kintai_bsc={Kintai_bsc}
                    setKintai_bsc={setKintai_bsc} 
                    Shift_msts={Shift_msts} 
                    shiftStartDay={shiftStartDay}
                    Department_msts={Department_msts}
                    setRequest_overtimes={setRequest_overtimes}
                    setRequest_paid_holiday={setRequest_paid_holiday}
                    display_user_info={display_user_info}
                    setDisplay_user_info={setDisplay_user_info}
                    Users={Users}
                />
            </div>
            }
        </GenericTemplate>
    );
}

export default Kintai;